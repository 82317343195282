/*
 * Reset
 */

/* Use a more-intuitive box-sizing model.*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*Remove default margin*/
* {
  margin: 0;
}
/*Allow percentage-based heights in the application*/
html,
body {
  height: 100%;
}
/* Add accessible line-height and Improve text rendering*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*Improve media defaults*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*Remove built-in form typography styles*/
input,
button,
textarea,
select {
  font: inherit;
}
/*Avoid text overflows*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*Create a root stacking context*/
#root,
#__next {
  isolation: isolate;
}

/*
 * Base
 */

html {
  scroll-behavior: smooth;
  /*  font-size:26px;*/
}

@media (min-width: 1200px) {
  html {
    /*    font-size:16px;*/
  }
}

@font-face {
  font-family: "helveticabold";
  src: url("./assets/fonts/helvetica-bold-font-webfont.woff2") format("woff2"),
    url("./assets/fonts/helvetica-bold-font-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  background: #767978;
  color: white;
  margin: 0;
  font-family: "helveticabold";
}

.visually-hidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.marquee {
  width: 100vw;
  position: absolute;
  overflow: hidden;
  top: calc(100vh - 114px - 4rem);
  top: calc(100svh - 114px - 4rem);
  z-index: 100;
}

.marquee-content {
  display: flex;
  animation: scrolling 10s linear infinite;
}

.marquee-content:hover {
  animation-play-state: paused;
}

.marquee-item {
  flex: 0 0 5rem;
  margin: 0 0.25rem;
}

.marquee-item img {
  display: block;
  width: 100%;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}

button,
a {
  border: none;
  font-family: "helveticabold";
  background: transparent;
  text-decoration: none;
  padding: 0;
  color: white;
  font-size: 0.75rem;
  text-transform: uppercase;

  position: relative;
  z-index: 9999;
}

button:hover,
a:hover {
  cursor: pointer;
  color: white;
}

button:focus,
a:focus {
  outline: none;
  color: white;
}

.buttonBuy {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  z-index: 400;
}

/*
 * Shopfy
 */

.shopify-buy-frame--product {
  width: 100%;
  max-width: 1000px;
  padding: 1rem 2rem;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.showProduct .shopify-buy-frame--product {
  top: 4rem;
  transform: translateY(0%);
  padding-bottom: 2rem;
}

.visuallyhidden {
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.shopify-buy__select-icon {
  display: none;
}

.shopify-buy__product {
  position: relative;
}

.shopify-buy__carousel {
  display: none;
}
.main-image-wrapper {
  position: relative;
}
.carousel-button {
  position: absolute;
  width: 50%;
  top: 0;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.carousel-button-arrow {
  display: none;
}
.carousel-button--previous {
  left: 0;
  transform: rotate(180deg);
}
.carousel-button--next {
  right: 0;
}

.shopify-buy__product__variant-img {
  margin: 0 auto 15px auto;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 1;
  object-fit: contain;
  width: 100%;
}

.shopify-buy__product__variant-img.is-transitioning {
  opacity: 0;
}
.shopify-buy__is-button {
  cursor: pointer;
}
.shopify-buy__no-image .shopify-buy__product__variant-img {
  display: none;
}

.shopify-buy__btn {
  color: white;
  border: 2px solid white;
  margin: 1rem 0;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 1rem 1rem;
}

.shopify-buy__option-select__select {
  padding: 0.5rem;
}

.shopify-buy__btn:hover,
.shopify-buy__option-select__select:hover {
  cursor: pointer;
  color: black;
  background: white;
}

.shopify-buy__btn-disabled {
  border-color: grey;
}

.shopify-buy__btn-disabled:hover {
  cursor: default;
}

.shopify-buy__product__title,
.shopify-buy__product__actual-price {
  font-weight: normal;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.shopify-buy__btn-disabled {
  background-color: grey;
}

.shopify-buy__product-description {
  padding-top: 2rem;
  font-size: 0.75rem;
  text-align: justify;
}

.shopify-buy__product-description p {
  padding-bottom: 1rem;
}

.showDescription .shopify-buy__product-description {
  display: block;
}

.shopify-buy__btn.shopify-buy__btn-disabled {
  background: transparent;
  color: gray;
}

.shopify-buy__product__variant-selectors {
  margin: 1rem 0;
}

/*
 * Detection
 */

.removed {
  display: none;
}

.invisible {
  opacity: 0;
}

nav button {
  position: absolute;
  transform: translateY(50%);
  top: 50%;

  color: white;
  z-index: 3;
  padding: 0.75rem 2rem 1rem 2rem;
}

@media (orientation: portrait) {
  nav button {
    position: absolute;
    top: 0;

    color: white;
    z-index: 3;
    padding: 0.75rem 2rem 1rem 2rem;
  }
}

nav button:hover {
  /*  background: white;*/
  color: grey;
}

#webcamButton {
  z-index: 3;
  /*  border: 2px solid white;*/
  padding: 0.75rem 2rem 1rem 2rem;
}

#productButton {
  right: 0;
  z-index: 4;
}

/*#webcamButton.addBorder {
  border: 2px solid white;
  padding: 0.75rem 1rem 1rem 1rem;
}

#webcamButton.addBorder:hover {
  color: white;
  border-color: white;
}*/

#camera {
  height: 100svh;
  height: 100vh;
  top: 0;
  position: absolute;
  left: 0;
  opacity: 0.2;
}

/*#demos::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    45deg,
    #000 25%,
    transparent 25%,
    transparent 50%,
    #000 50%,
    #000 75%,
    transparent 75%,
    transparent
  );
  background-size: 4px 4px;
  z-index: 2;
}*/

video {
  position: fixed;
  top: 0;
  object-fit: fill;
  filter: grayscale();
  width: 100%;
  height: 100%;
  min-height: 600px;
  min-width: 800px;
}

#cameraLive {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  position: absolute;
  top: 0;
  min-height: 600px;
  min-width: 800px;
}

#cameraLive p {
  position: absolute;
  padding: 5px 10px;
  color: #fff;
  z-index: 3;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin: 0;
}

.highlighter {
  border: 2px solid #fff;
  z-index: 3;
  position: absolute;
}

body {
  background: #111;
  color: white;
}

#switch {
  display: none;
}

.switch-label {
  display: inline-block;
  cursor: pointer;
  background: #fff;
  color: #111;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 2px #666;
}
.switch-label::after {
  content: "on";
}
.switch-label::before {
  content: " ";
  display: inline-block;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background: #003321;
  margin-right: 10px;
  border: 1px solid #111;
}
.switch-label:active {
  box-shadow: none;
  transform: translate3d(0, 2px, 0);
}

#switch:checked + .switch-label::before {
  background: #22ff55;
}
#switch:checked + .switch-label::after {
  content: "off";
}

@keyframes flicker {
  0% {
    opacity: 0.02672;
  }
  5% {
    opacity: 0.17697;
  }
  10% {
    opacity: 0.36135;
  }
  15% {
    opacity: 0.42701;
  }
  20% {
    opacity: 0.04651;
  }
  25% {
    opacity: 0.24416;
  }
  30% {
    opacity: 0.99015;
  }
  35% {
    opacity: 0.86221;
  }
  40% {
    opacity: 0.83998;
  }
  45% {
    opacity: 0.96702;
  }
  50% {
    opacity: 0.70852;
  }
  55% {
    opacity: 0.86839;
  }
  60% {
    opacity: 0.86633;
  }
  65% {
    opacity: 0.89955;
  }
  70% {
    opacity: 0.38483;
  }
  75% {
    opacity: 0.93443;
  }
  80% {
    opacity: 0.18581;
  }
  85% {
    opacity: 0.89911;
  }
  90% {
    opacity: 0.02619;
  }
  95% {
    opacity: 0.48835;
  }
  100% {
    opacity: 0.95957;
  }
}
.container {
  background: #121010;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.container::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
}
.container::before {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.06)
    );
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.9) inset;
}

#switch:checked ~ .container::after {
  animation: flicker 0.15s infinite;
}

@keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 1;
  }
  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }
  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }
  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 0;
  }
  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
  }
}
@keyframes turn-off {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }
  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}
.screen {
  width: 100%;
  height: 100%;
  border: none;
}

#switch ~ .container > .screen {
  animation: turn-off 0.55s cubic-bezier(0.23, 1, 0.32, 1);
  animation-fill-mode: forwards;
}

#switch:checked ~ .container > .screen {
  animation: turn-on 4s linear;
  animation-fill-mode: forwards;
}

@keyframes overlay-anim {
  0% {
    visibility: hidden;
  }
  20% {
    visibility: hidden;
  }
  21% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
.overlay {
  color: #00ff00;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 60px;
  visibility: hidden;
  pointer-events: none;
}

#switch:checked ~ .container .overlay {
  animation: overlay-anim 5s linear;
  animation-fill-mode: forwards;
}

.shopify-buy__product__title,
.shopify-buy__product__actual-price,
.shopify-buy__product__variant-selectors,
.shopify-buy__btn-wrapper,
.shopify-buy__product-description {
  display: none;
}

.showProduct .shopify-buy__product__title,
.showProduct .shopify-buy__product__actual-price,
.showProduct .shopify-buy__product__variant-selectors,
.showProduct .shopify-buy__btn-wrapper,
.showProduct .shopify-buy__product-description {
  display: block;
}

.showProduct #productButton {
  display: none;
}
