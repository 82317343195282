*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: helveticabold;
  src: url("helvetica-bold-font-webfont.38480412.woff2") format("woff2"), url("helvetica-bold-font-webfont.09262a00.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  color: #fff;
  background: #767978;
  margin: 0;
  font-family: helveticabold;
}

.visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.marquee {
  z-index: 100;
  width: 100vw;
  position: absolute;
  top: calc(100svh - 114px - 4rem);
  overflow: hidden;
}

.marquee-content {
  animation: 10s linear infinite scrolling;
  display: flex;
}

.marquee-content:hover {
  animation-play-state: paused;
}

.marquee-item {
  flex: 0 0 5rem;
  margin: 0 .25rem;
}

.marquee-item img {
  width: 100%;
  display: block;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-144vw);
  }
}

button, a {
  color: #fff;
  text-transform: uppercase;
  z-index: 9999;
  background: none;
  border: none;
  padding: 0;
  font-family: helveticabold;
  font-size: .75rem;
  text-decoration: none;
  position: relative;
}

button:hover, a:hover {
  cursor: pointer;
  color: #fff;
}

button:focus, a:focus {
  color: #fff;
  outline: none;
}

.buttonBuy {
  color: #fff;
  z-index: 400;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}

.shopify-buy-frame--product {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem 2rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.showProduct .shopify-buy-frame--product {
  padding-bottom: 2rem;
  top: 4rem;
  transform: translateY(0%);
}

.visuallyhidden {
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.shopify-buy__select-icon {
  display: none;
}

.shopify-buy__product {
  position: relative;
}

.shopify-buy__carousel {
  display: none;
}

.main-image-wrapper {
  position: relative;
}

.carousel-button {
  cursor: pointer;
  opacity: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
}

.carousel-button-arrow {
  display: none;
}

.carousel-button--previous {
  left: 0;
  transform: rotate(180deg);
}

.carousel-button--next {
  right: 0;
}

.shopify-buy__product__variant-img {
  opacity: 1;
  object-fit: contain;
  width: 100%;
  margin: 0 auto 15px;
  transition: opacity .3s;
}

.shopify-buy__product__variant-img.is-transitioning {
  opacity: 0;
}

.shopify-buy__is-button {
  cursor: pointer;
}

.shopify-buy__no-image .shopify-buy__product__variant-img {
  display: none;
}

.shopify-buy__btn {
  color: #fff;
  border: 2px solid #fff;
  border-radius: .5rem;
  margin: 1rem 0;
  padding: .75rem 1rem 1rem;
}

.shopify-buy__option-select__select {
  padding: .5rem;
}

.shopify-buy__btn:hover, .shopify-buy__option-select__select:hover {
  cursor: pointer;
  color: #000;
  background: #fff;
}

.shopify-buy__btn-disabled {
  border-color: gray;
}

.shopify-buy__btn-disabled:hover {
  cursor: default;
}

.shopify-buy__product__title, .shopify-buy__product__actual-price {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: normal;
}

.shopify-buy__btn-disabled {
  background-color: gray;
}

.shopify-buy__product-description {
  text-align: justify;
  padding-top: 2rem;
  font-size: .75rem;
}

.shopify-buy__product-description p {
  padding-bottom: 1rem;
}

.showDescription .shopify-buy__product-description {
  display: block;
}

.shopify-buy__btn.shopify-buy__btn-disabled {
  color: gray;
  background: none;
}

.shopify-buy__product__variant-selectors {
  margin: 1rem 0;
}

.removed {
  display: none;
}

.invisible {
  opacity: 0;
}

nav button {
  color: #fff;
  z-index: 3;
  padding: .75rem 2rem 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(50%);
}

@media (orientation: portrait) {
  nav button {
    color: #fff;
    z-index: 3;
    padding: .75rem 2rem 1rem;
    position: absolute;
    top: 0;
  }
}

nav button:hover {
  color: gray;
}

#webcamButton {
  z-index: 3;
  padding: .75rem 2rem 1rem;
}

#productButton {
  z-index: 4;
  right: 0;
}

#camera {
  opacity: .2;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

video {
  object-fit: fill;
  filter: grayscale();
  width: 100%;
  min-width: 800px;
  height: 100%;
  min-height: 600px;
  position: fixed;
  top: 0;
}

#cameraLive {
  width: 100vw;
  min-width: 800px;
  height: 100svh;
  min-height: 600px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

#cameraLive p {
  color: #fff;
  z-index: 3;
  text-transform: uppercase;
  margin: 0;
  padding: 5px 10px;
  font-size: .75rem;
  position: absolute;
}

.highlighter {
  z-index: 3;
  border: 2px solid #fff;
  position: absolute;
}

body {
  color: #fff;
  background: #111;
}

#switch {
  display: none;
}

.switch-label {
  cursor: pointer;
  color: #111;
  background: #fff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 15px;
  display: inline-block;
  box-shadow: 0 2px #666;
}

.switch-label:after {
  content: "on";
}

.switch-label:before {
  content: " ";
  background: #003321;
  border: 1px solid #111;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

.switch-label:active {
  box-shadow: none;
  transform: translate3d(0, 2px, 0);
}

#switch:checked + .switch-label:before {
  background: #2f5;
}

#switch:checked + .switch-label:after {
  content: "off";
}

@keyframes flicker {
  0% {
    opacity: .02672;
  }

  5% {
    opacity: .17697;
  }

  10% {
    opacity: .36135;
  }

  15% {
    opacity: .42701;
  }

  20% {
    opacity: .04651;
  }

  25% {
    opacity: .24416;
  }

  30% {
    opacity: .99015;
  }

  35% {
    opacity: .86221;
  }

  40% {
    opacity: .83998;
  }

  45% {
    opacity: .96702;
  }

  50% {
    opacity: .70852;
  }

  55% {
    opacity: .86839;
  }

  60% {
    opacity: .86633;
  }

  65% {
    opacity: .89955;
  }

  70% {
    opacity: .38483;
  }

  75% {
    opacity: .93443;
  }

  80% {
    opacity: .18581;
  }

  85% {
    opacity: .89911;
  }

  90% {
    opacity: .02619;
  }

  95% {
    opacity: .48835;
  }

  100% {
    opacity: .95957;
  }
}

.container {
  background: #121010;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.container:after {
  content: " ";
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  background: #1210101a;
  display: block;
  position: absolute;
  inset: 0;
}

.container:before {
  content: " ";
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(#12101000 50%, #00000040 50%) 0 0 / 100% 2px, linear-gradient(90deg, #ff00000f, #00ff0005, #0000ff0f) 0 0 / 3px 100%;
  display: block;
  position: fixed;
  inset: 0;
  box-shadow: inset 0 0 200px #000000e6;
}

#switch:checked ~ .container:after {
  animation: .15s infinite flicker;
}

@keyframes turn-on {
  0% {
    filter: brightness(30);
    opacity: 1;
    transform: scale(1, .8)translate3d(0, 0, 0);
  }

  3.5% {
    transform: scale(1, .8)translate3d(0, 100%, 0);
  }

  3.6% {
    opacity: 1;
    transform: scale(1, .8)translate3d(0, -100%, 0);
  }

  9% {
    filter: brightness(30);
    opacity: 0;
    transform: scale(1.3, .6)translate3d(0, 100%, 0);
  }

  11% {
    filter: contrast(0) brightness(0);
    opacity: 0;
    transform: scale(1)translate3d(0, 0, 0);
  }

  100% {
    filter: contrast() brightness(1.2) saturate(1.3);
    opacity: 1;
    transform: scale(1)translate3d(0, 0, 0);
  }
}

@keyframes turn-off {
  0% {
    filter: brightness();
    opacity: 1;
    transform: scale(1, 1.3)translate3d(0, 0, 0);
  }

  60% {
    filter: brightness(10);
    transform: scale(1.3, .001)translate3d(0, 0, 0);
  }

  100% {
    filter: brightness(50);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: scale(0, .0001)translate3d(0, 0, 0);
  }
}

.screen {
  border: none;
  width: 100%;
  height: 100%;
}

#switch ~ .container > .screen {
  animation: .55s cubic-bezier(.23, 1, .32, 1) forwards turn-off;
}

#switch:checked ~ .container > .screen {
  animation: 4s linear forwards turn-on;
}

@keyframes overlay-anim {
  0% {
    visibility: hidden;
  }

  20% {
    visibility: hidden;
  }

  21% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

.overlay {
  color: #0f0;
  visibility: hidden;
  pointer-events: none;
  font-size: 60px;
  position: absolute;
  top: 20px;
  left: 20px;
}

#switch:checked ~ .container .overlay {
  animation: 5s linear forwards overlay-anim;
}

.shopify-buy__product__title, .shopify-buy__product__actual-price, .shopify-buy__product__variant-selectors, .shopify-buy__btn-wrapper, .shopify-buy__product-description {
  display: none;
}

.showProduct .shopify-buy__product__title, .showProduct .shopify-buy__product__actual-price, .showProduct .shopify-buy__product__variant-selectors, .showProduct .shopify-buy__btn-wrapper, .showProduct .shopify-buy__product-description {
  display: block;
}

.showProduct #productButton {
  display: none;
}
/*# sourceMappingURL=index.313f2c9c.css.map */
